Vue.component('contract-variables', {
    props: {
        translations: Object,
        updateUrl: String,
        initialData: Object
    },
    data: function () {
        return {
            form_fields: {
                type_sla: {
                    default: 'inside',
                    options: [
                        'inside',
                        'greenwall',
                        'outside',
                        'artificial',
                        'christmas',
                    ]
                },
                based_on: {
                    default: 'allin',
                    options: [
                        'allin',
                        'lease',
                    ]
                },
                sla_options: {
                    default: true
                },
                sla_options_text: {
                    default: ''
                },
                visits: {
                    default: 12,
                    min: 1,
                    max: 12
                },
                extra_visits: {
                    default: 0
                },
                payment_term: {
                    default: 14,
                    options: [
                        14,
                        30,
                        60,
                        90,
                    ]
                },
                contract_duration: {
                    default: 1,
                },
                notice_period: {
                    default: 6
                },
                needs_signing: {
                    default: false
                },
                needs_signing_name: {
                    default: ''
                },
                additionals: {
                    default: [
                        {
                            enabled: true,
                            label: 'Parkeerkosten',
                            amount: null,
                            description: null
                        },
                        {
                            enabled: true,
                            label: 'Huur hoogwerker',
                            amount: null,
                            description: null
                        }
                    ],
                    template: {
                        enabled: true,
                        label: null,
                        amount: null,
                        description: null
                    }
                }
            },
            form_data: {

            },
            debounce_timer: null,
            debounce_interval: 500
        }
    },
    computed: {
    },
    watch: {
        form_data: {
            deep: true,
            handler() {
                clearTimeout(this.debounce_timer);

                this.debounce_timer = setTimeout(this.update, this.debounce_interval);
            }
        },
    },
    methods: {
        trans(key) {
            return this.translations[key].replace(/\\n/g, "\n");
        },
        update() {
            API.PUT(this.updateUrl, this.form_data);
        },
        deleteAdditional(index) {
            Vue.set(
                this.form_data,
                'additionals',
                this.form_data.additionals.filter((a, i) => i !== index));

        },
        addAdditional() {
            Vue.set(
                this.form_data,
                'additionals',
                [...this.form_data.additionals, JSON.parse(JSON.stringify(this.form_fields.additionals.template))]);
        }
    },
    created() {
        for(var name in this.form_fields) {
            Vue.set(
                this.form_data,
                name,
                (this.initialData.hasOwnProperty(name)
                    ? this.initialData[name]
                    : (this.form_fields[name].hasOwnProperty('default')
                        ? this.form_fields[name].default
                        : null))
            );
        }
    },
    mounted() {
    },
    destroyed() {
    }
});
