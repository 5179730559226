Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("order-deliveryform")) {
        Global.components.push(
            new Vue({
                el: '#order-deliveryform',
                store,
                data: function () {
                    return {
                        hasCaptcha: false,
                        captchaExecuted: false,
                        unloading_parking: [],
                        technical_back_wall: null,
                        deliveryForm: null,
                        delivery_date_preference: '',
                        delivery_openinghours_preference: '',
                        invoice_same_as_delivery: '',
                        additional_info: '',
                        additional_extra: '',
                        first_floor: '',
                        elevator_present: '',
                        elevator_fits: '',
                        arrival_report: '',
                        expedition_usable: '',
                        use_purchase_number: '',
                        use_maintainance_purchase_number: '',
                        other_maintainance_address: '',
                        maintainance_needed: '',
                        escort_needed: '',
                        signup_needed: '',
                        alternatives_allowed: '',
                        alternatives_preferences: '',
                        technical_elevator_present: '',
                        technical_goods_fit_in_elevator: '',
                    }
                },
                components: {
                    'vue-recaptcha': VueRecaptcha
                },
                computed: {
                },
                watch: {
                    additional_info() {
                        this.additional_extra = '';
                    }
                },
                created: function() {
                    let checkTechnicalBackWall = document.querySelector('input[name="technical_back_wall"]:checked');
                    if(checkTechnicalBackWall !== null) {
                        this.technical_back_wall = checkTechnicalBackWall.value;
                    }

                    let checkedUnloadingParking = document.querySelectorAll('input[name="unloading_parking[]"]:checked');
                    if(checkedUnloadingParking.length > 0) {
                        for(let x = 0 ; x < checkedUnloadingParking.length ; x++) {
                            this.unloading_parking.push(checkedUnloadingParking[x].value);
                        }
                    }
                    this.fillForm();
                },
                mounted: function () {
                    this.$nextTick((bla) => {
                        this.fillForm();
                    });
                },
                methods: {
                    fillForm() {
                        this.deliveryForm = window.deliveryForm;
                        this.delivery_date_preference = this.deliveryForm.delivery_date_preference;
                        this.delivery_openinghours_preference = this.deliveryForm.delivery_openinghours_preference;
                        this.invoice_same_as_delivery = this.deliveryForm.invoice_same_as_delivery;
                        this.additional_info = this.deliveryForm.additional_info;
                        this.additional_extra = this.deliveryForm.additional_extra;
                        this.first_floor = this.deliveryForm.first_floor;
                        this.elevator_present = this.deliveryForm.technical_elevator_present;
                        this.elevator_fits = this.deliveryForm.technical_goods_fit_in_elevator;
                        this.arrival_report = this.deliveryForm.arrival_report;
                        this.expedition_usable = this.deliveryForm.expedition_usable;
                        this.use_purchase_number = this.deliveryForm.use_purchase_number;
                        this.use_maintainance_purchase_number = this.deliveryForm.use_maintainance_purchase_number;
                        this.other_maintainance_address = this.deliveryForm.other_maintainance_address;
                        this.maintainance_needed = this.deliveryForm.maintainance_needed;
                        this.escort_needed = this.deliveryForm.escort_needed;
                        this.signup_needed = this.deliveryForm.signup_needed;
                        this.alternatives_allowed = this.deliveryForm.alternatives_allowed;
                        this.alternatives_preferences = this.deliveryForm.alternatives_preferences;
                    },
                    submit: function () {
                        const that = this;
                        this.$validator.validateAll().then(result => {
                            let analytics = that.$el.dataset.analytics;

                            if (result) {
                                if (!this.hasCaptcha || this.captchaExecuted) {
                                    Global.SubmitForm(that.$el, analytics);
                                } else {
                                    this.$refs.recaptcha.execute();
                                }
                            }
                        });
                    },
                    onCaptchaVerified: function () {
                        this.captchaExecuted = true;
                        this.submit();
                    },
                    checkDates: function() {
                        let fromDate = (this.$refs.delivery_date_from.value != '' ? moment(this.$refs.delivery_date_from.value) : null);
                        let toDate = (this.$refs.delivery_date_to.value != '' ? moment(this.$refs.delivery_date_to.value) : null);

                        let minDate = moment(this.$refs.delivery_date_from.min);

                        if(fromDate !== null) {
                            // fromDate must always be at least the minDate
                            if (fromDate < minDate) {
                                fromDate = minDate;
                            }
                        }

                        if(toDate !== null) {

                            // toDate must always be at least the minDate
                            if (toDate < minDate) {
                                toDate = minDate;
                            }

                            // toDate must always be equal to or higher then fromDate
                            if (toDate < fromDate) {
                                toDate = fromDate;
                            }
                        }

                        // Write back the values
                        if(fromDate !== null) {
                            this.$refs.delivery_date_from.value = fromDate.format('YYYY-MM-DD');
                            this.$refs.delivery_date_to.min = fromDate.format('YYYY-MM-DD');
                        }

                        if(toDate !== null) {
                            this.$refs.delivery_date_to.value = toDate.format('YYYY-MM-DD');
                        }

                    }
                },
                destroyed: function () {
                }
            })
        )
    }
});
