Barba.Dispatcher.on("transitionCompleted", function () {
    if (!document.getElementById("quotation")) {
        return;
    }
    Global.components.push(
        new Vue({
            el: '#quotation',
            store,
            data: function () {
                return {
                    projectID: document.getElementById("quotation").dataset.projectId,

                    contentLoaded: false,

                    lines: '',
                    agreements: '',

                    updateUrl: document.getElementById("quotation").dataset.updateUrl,
                    editable: false,
                    editableFields: null,
                    editableFieldsTimer: null,
                    shareSelectedAgreement: null,
                }
            },
            computed: {
                showContractVariables() {
                    if(this.agreements === '') {
                        return false;
                    }

                    return this.agreements.filter((a) => !a.hidden && (a.isLease || a.maintenance)).length > 0;
                },
                shareShowContractLines() {
                    return this.shareSelectedAgreement !== null && (this.shareSelectedAgreement.indexOf('lease') >= 0
                        || this.shareSelectedAgreement.indexOf('buy-with-maintenance') >= 0);
                }
            },
            mounted: function() {

                let selectElement = document.getElementById('accept_agreement');
                if(selectElement) {
                    this.shareSelectedAgreement = selectElement.options[0].value;
                }


                // var url = this.deliveryCostsGetUrl + "?projectID=" + this.projectID;
                //
                // const that = this;
                //
                // API.GET(url).then(response => {
                //     this.handleResponse(that, response);
                // });
            },
            beforeMount() {
                var url = this.updateUrl;

                const that = this;

                API.GET(url).then(response => {
                    this.handleResponse(that, response);
                });
            },
            methods: {
                handleResponse: function(scope, response) {
                    scope.lines = response.lines;
                    scope.agreements = response.agreements;

                    scope.editable = response.editable;
                    scope.editableFields = response.fields;

                    scope.contentLoaded = true;
                },
                onDeliveryModified(payload) {
                    this.editableFields.delivery.modified = payload.modified;
                    this.editableFields.delivery.value = payload.value;

                    this.onPriceModified();
                },
                onArrangementModified(payload) {
                    this.editableFields.arrangement.modified = payload.modified;
                    this.editableFields.arrangement.value = payload.value;

                    this.onPriceModified();
                },
                onMaintenanceModified(payload) {
                    this.editableFields.maintenance.modified = payload.modified;

                    this.onPriceModified();
                },
                onPriceModified() {
                    clearTimeout(this.editableFieldsTimer);

                    this.editableFieldsTimer = setTimeout(function() {
                        API.PUT(this.updateUrl, this.editableFields).then(response => {
                            this.handleResponse(this, response);
                        });
                    }.bind(this), 250);
                },
                /*onDeliveryCostsKeyUp() {
                    clearTimeout(this.deliveryCostsKeyUpTimer);

                    const that = this;

                    if (this.deliveryCosts.length > 1) {
                        this.deliveryCostsKeyUpTimer = setTimeout(function () {
                            this.deliveryCostsKeyUpTimer = null;

                            var data = {
                                deliveryCosts: this.deliveryCosts,
                                projectID: this.projectID
                            }

                            API.POST(this.deliveryCostsPostUrl, data).then(response => {
                                that.handleResponse(that, response);
                            });
                        }.bind(this), 500);
                    }
                }*/
                onAgreementsModified(response) {
                    let hiddenAgreementKeys = response;
                    this.agreements.forEach((a) => a.hidden = hiddenAgreementKeys.indexOf(a.id) >= 0)
                }
            },
            watch: {

            }
        })
    )
});
